// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27100%25%27 height=%27100%25%27 viewBox=%270 0 2 1%27%3E%3Cdefs%3E%3ClinearGradient id=%27a%27 gradientUnits=%27userSpaceOnUse%27 x1=%270%27 x2=%270%27 y1=%270%27 y2=%271%27%3E%3Cstop offset=%270%27 stop-color=%27%2377aa77%27/%3E%3Cstop offset=%271%27 stop-color=%27%234fd%27/%3E%3C/linearGradient%3E%3ClinearGradient id=%27b%27 gradientUnits=%27userSpaceOnUse%27 x1=%270%27 y1=%270%27 x2=%270%27 y2=%271%27%3E%3Cstop offset=%270%27 stop-color=%27%23cf8%27 stop-opacity=%270%27/%3E%3Cstop offset=%271%27 stop-color=%27%23cf8%27 stop-opacity=%271%27/%3E%3C/linearGradient%3E%3ClinearGradient id=%27c%27 gradientUnits=%27userSpaceOnUse%27 x1=%270%27 y1=%270%27 x2=%272%27 y2=%272%27%3E%3Cstop offset=%270%27 stop-color=%27%23cf8%27 stop-opacity=%270%27/%3E%3Cstop offset=%271%27 stop-color=%27%23cf8%27 stop-opacity=%271%27/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x=%270%27 y=%270%27 fill=%27url%28%23a%29%27 width=%272%27 height=%271%27/%3E%3Cg fill-opacity=%270.5%27%3E%3Cpolygon fill=%27url%28%23b%29%27 points=%270 1 0 0 2 0%27/%3E%3Cpolygon fill=%27url%28%23c%29%27 points=%272 1 2 0 0 0%27/%3E%3C/g%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #77aa77;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-attachment: fixed;
    background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yDAAm/B;IACn/B,4BAA4B;IAC5B,sBAAsB;AAC1B","sourcesContent":["body {\n    background-color: #77aa77;\n    background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2377aa77'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E\");\n    background-attachment: fixed;\n    background-size: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
